<template>
  <v-container>
    <v-row align="start" justify="end">
      <h1><div class="subtitle navy-text"><strong>PAYMENT</strong><br/></div></h1>
    </v-row>
    <v-row  align="start" justify="end">
      <div class="base-text">การชำระเงิน</div>
    </v-row>
    <v-row>
      <v-divider></v-divider><br/>
    </v-row>
    <v-tabs  show-arrows
    :mobile-break-point='300'
    :max='300'
    v-model="tabs"
    >
      <v-tab :key="1">
        INVOICES
      </v-tab>
      <v-tab :key="2">
        ORDER HISTORY
      </v-tab>
      <!-- <v-tab :key="3">
        BILLING ADDRESS
      </v-tab> -->
      <v-tabs-items v-model="tabs">
        <v-tab-item :key="1">
          <Invoice/>
        </v-tab-item>
        <v-tab-item :key="2">
          <OrderHistory/>
        </v-tab-item>
        <!-- <v-tab-item :key="3">
          <BillingAddress/>
        </v-tab-item> -->
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import Invoice from '@/components/Invoice.vue';
import OrderHistory from '@/components/OrderHistory.vue';
// import BillingAddress from '@/components/BillingAddress.vue';

export default {
  data() {
    return {
      tab: null,
      tabs: null,

    };
  },
  components: {
    Invoice,
    OrderHistory,
    // BillingAddress,
  },
};
</script>
