var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{attrs:{"tag":"caption"}},[_vm._v("ประวัติการชำระเงิน")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoice,"options":_vm.paging,"show-expand":""},on:{"update:options":function($event){_vm.paging=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" บาท ")]}},{key:"item.receipt",fn:function(ref){
var item = ref.item;
return [(!item.disable)?_c('div',[_c('a',{on:{"click":function($event){return _vm.createReciept(item)}}},[_vm._v("Download")])]):_c('div',{staticClass:"grey--text"},[_vm._v(" N/A ")])]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("expand_more")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},_vm._l((item.itemsList),function(i,index){return _c('div',{key:i.title},[_vm._v(" "+_vm._s(index + 1)+".) "+_vm._s(i.title)+" ")])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }