import firebase from '../index';

// PRODUCTS model
// sku --- key
// host --- product's host [achieve.plus, cuma, etc]
// type --- product's type [workshop, pathway, etc]
// detail --- product's common details {earlyPeriod, sellPeriod, price, limit}
// participator --- array of user who purchase product [uids]

// moving to product system
// const products = firebase.firestore().collection('products');

// const getProducts = () => new Promise((resolve, reject) => {
//   products.get()
//     .then((snapshot) => {
//       resolve(snapshot.docs.map(doc => doc.data()));
//     })
//     .catch((e) => {
//       reject(e);
//     });
// });

// const getProduct = ({ sku }) => new Promise((resolve, reject) => {
//   products.doc(sku).get()
//     .then((snapshot) => {
//       resolve(snapshot.data());
//     })
//     .catch((e) => {
//       reject(e);
//     });
// });

const workshops = firebase.firestore().collection('workshops');

const getWorkshops = () => new Promise((resolve, reject) => {
  workshops.orderBy('saleEnd', 'desc').get()
    .then((snapshot) => {
      resolve(snapshot.docs.map(doc => doc.data()));
    })
    .catch((e) => {
      reject(e);
    });
});

const getWorkshop = ({ id }) => new Promise((resolve, reject) => {
  workshops.doc(id).get()
    .then((snapshot) => {
      resolve(snapshot.data());
    })
    .catch((e) => {
      reject(e);
    });
});

export default {
  getWorkshops,
  getWorkshop,
  // getProducts,
  // getProduct,
};
