<template>
  <v-container>
    <div tag="caption">ประวัติการชำระเงิน</div>
    <br/>
      <v-data-table
      :headers="headers"
      :items="invoice"
      :options.sync="paging"
      show-expand
      class="elevation-1"
      >
      <template v-slot:item.price="{ item }">
        {{ item.price }} บาท
      </template>
      <template v-slot:item.receipt="{ item }">
        <div v-if="!item.disable">
          <a @click="createReciept(item)">Download</a>
        </div>
        <div v-else class="grey--text">
          N/A
        </div>
      </template>
      <template v-slot:item.data-table-expand="{ expand, isExpanded }">
        <v-icon @click="expand(!isExpanded)">expand_more</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <div v-for="(i, index) in item.itemsList" :key="i.title">
            {{ index + 1 }}.) {{ i.title }}
          </div>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Jspdf from 'jspdf';
import moment from 'moment';
import workshops from '../firebase/firestore/workshops';
import pathways from '../firebase/firestore/pathways';
import payments from '../firebase/firestore/payments';
import products from '../library/products';
import fonts from '../static/fonts';
import logo from '../static/logo';

export default {
  name: 'Invoice',
  data: () => ({
    // orderId: 'AP1905000010',
    // productName: 'Python for Clustering Customer Segmentation (27 เม.ย. 2562)',
    // price: 5900.00,
    // orderDate: '21/05/2562',
    expanded: [],
    headers: [
      {
        text: 'INVOICE ID',
        value: 'id',
      },
      { text: 'INVOICE DATE', value: 'orderdate' },
      { text: 'PRODUCT NAME', value: 'productname' },
      { text: 'PRICE', value: 'price' },
      { text: 'RECEIPT', value: 'receipt' },
      { text: '', value: 'data-table-expand' },
    ],
    invoice: [
    ],
    paging: {
      descending: true,
    },
  }),
  async mounted() {
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    const trans = await payments.getUserTransactions({ uid });
    const filterTrans = trans.filter(tran => tran.invoiceId !== undefined);
    this.invoice = await Promise.all(filterTrans.map(async (tran) => {
      const odId = tran.orderId;
      const order = await payments.getSingleOrder({ odId });
      const { sku, workshopId } = order;
      const id = `${workshopId}`;
      const product = sku ? await products.getProduct(sku)
        : await workshops.getWorkshop({ id });
      let transaction;
      let itemsList;
      if (product) {
        if (product.items) {
          itemsList = await Promise.all(
            product.items.map((item) => {
              let detail;
              if (item.type === 'workshop') {
                detail = workshops.getWorkshop({ id: `${item.id}` });
              } else if (item.type === 'pathway') {
                detail = pathways.getPathway({ id: `${item.id}` });
              }
              return detail;
            }),
          );
        }
        transaction = {
          id: tran.invoiceId,
          orderdate: moment(tran.added.toDate()).format('YYYY-MM-DD'),
          productname: product.name || (product.ep ? `${product.title} ${product.ep}` : product.title),
          price: order.price - order.discount,
          fullPrice: order.price,
          discount: order.discount,
          method: tran.method,
          od: tran.orderId,
        };
        if (order.post) {
          transaction.disable = true;
        }
        if (itemsList) transaction.itemsList = itemsList;
        if (sku) transaction.sku = sku;
      }
      return transaction;
    }));
    this.invoice = this.invoice.filter(tran => tran !== undefined);
    this.$store.dispatch('setLoading', false);
  },
  methods: {
    async createReciept(data) {
      const pdfName = `AP${data.id} - Receipt`;
      const doc = new Jspdf();
      doc.addFileToVFS('Prompt-Regular.ttf', fonts.PROMPT_NORMAL);
      doc.addFileToVFS('Prompt-Bold.ttf', fonts.PROMPT_BOLD);
      doc.addFont('Prompt-Regular.ttf', 'Prompt', 'normal');
      doc.addFont('Prompt-Bold.ttf', 'Prompt', 'bold');
      doc.setFont('Prompt');
      doc.setFontType('bold');
      doc.setFontSize(18);
      doc.addImage(logo.LOGO, 'PNG', 97, 15, 15, 15);
      doc.text('Achieve Plus', 85, 40);
      doc.setFontType('normal');
      doc.text('บิลเงินสด/ใบกำกับภาษีอย่างย่อ', 65, 55);
      doc.setFontSize(14);
      doc.text('(E-RECEIPT/TAX INVOICE)', 75, 65);
      doc.setFontSize(12);
      doc.text('บริษัท บางกอก อินโนเวชั่น เฮ้าส์ จำกัด (สำนักงานใหญ่)', 57, 80);
      doc.setFontSize(10);
      doc.text('183 อาคารรีเจ้นท์ เฮ้าส์ ชั้น 15 ถนนราชดำริ แขวงลุมพินี', 65, 88);
      doc.text('เขตปทุมวัน กรุงเทพฯ 10330 โทร. (02) 651-9069', 67, 94);
      doc.text('เลขประจำตัวผู้เสียภาษีอากร : 0105556138906', 70, 100);
      doc.setLineDash([5, 3, 1, 3], 10);
      doc.line(10, 110, 200, 110);
      doc.setFontSize(12);
      doc.text(`เลขที่ : AP${data.id}`, 15, 120);
      doc.text(`วันที่ ${moment(data.orderdate).format('DD/MM/YYYY')}`, 160, 120);
      doc.text(`อ้างอิง Order No. : ${data.od}`, 15, 127);
      doc.line(10, 135, 200, 135);
      doc.setFontSize(10);
      if (data.sku) doc.text(`${data.sku}: ${data.productname} x 1`, 15, 145);
      else doc.text(`${data.productname} x 1`, 15, 145);
      doc.text(`${data.fullPrice.toFixed(2)} บาท`, 175, 145);
      let i = 0;
      if (data.itemsList) {
        for (i = 0; i < data.itemsList.length; i += 1) {
          doc.text(`${i + 1}.) ${data.itemsList[i].title}`, 25, (155 + (i * 10)));
        }
      }
      doc.text('ส่วนลด', 15, (155 + (i * 10)));
      doc.text(`${data.discount.toFixed(2)} บาท`, 175, (155 + (i * 10)));
      doc.text('ยอดสุทธิ', 15, (175 + (i * 10)));
      doc.text(`${data.price.toFixed(2)} บาท`, 175, (175 + (i * 10)));
      doc.text(`ชำระโดย ${data.method}`, 80, (185 + (i * 10)));
      doc.text('(รวมภาษีมูลค่าเพิ่มแล้ว)', 85, (195 + (i * 10)));
      doc.line(10, (205 + (i * 10)), 200, (205 + (i * 10)));
      doc.setFontSize(20);
      doc.setFontSize(10);
      doc.text('หากมีข้อสงสัยเพิ่มเติม กรุณาติดต่อทีมงาน billing@achieve.plus', 53, 280);
      doc.save(`${pdfName}.pdf`);
    },
  },
};
</script>
