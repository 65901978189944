import productModule from '../firebase/firestore/products';
import store from '../store';

const { errorMsg } = require('../static/errorMessage');

const PRODUCT_TYPE = {
  WORKSHOP: 'workshop',
  PATHWAY: 'pathway',
  COMPANY_VISIT: 'company visit',
  COURSE: 'course',
};

const getAllProduct = () => productModule.getProducts()
  .catch((error) => {
    store.dispatch('setError', errorMsg['connection/unavailable']);
    throw error;
  });

const getProduct = sku => productModule.getProduct({ sku })
  .catch((error) => {
    store.dispatch('setError', errorMsg['connection/unavailable']);
    throw error;
  });

export default {
  PRODUCT_TYPE,
  getAllProduct,
  getProduct,
};
