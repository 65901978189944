<template>
  <v-container>
    <div tag="caption">ประวัติการสั่งซื้อ</div>
    <br/>
      <v-data-table
      :headers="headers"
      :items="orders"
      :options.sync="paging"
      show-expand
      class="elevation-1"
      >
      <template v-slot:item.price="{ item }">
        {{item.price}} บาท
      </template>
      <template v-slot:item.data-table-expand="{ expand, isExpanded }">
        <v-icon @click="expand(!isExpanded)">expand_more</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <div v-for="(i, index) in item.itemsList" :key="i.title">
            {{ index + 1 }}.) {{ i.title }}
          </div>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment';
import payments from '../firebase/firestore/payments';
import workshops from '../firebase/firestore/workshops';
import pathways from '../firebase/firestore/pathways';
import products from '../library/products';

export default {
  name: 'OrderHistory',
  data: () => ({
    // orderId: 'ODP1905000010',
    // productName: 'Python for Clustering Customer Segmentation (27 เม.ย. 2562)',
    // price: 5900.00,
    // orderDate: '21/05/2562',
    headers: [
      {
        text: 'ORDER ID',
        value: 'id',
      },
      { text: 'ORDER DATE', value: 'orderdate' },
      { text: 'PRODUCT NAME', value: 'productname' },
      { text: 'PRICE', value: 'price' },
      { text: 'STATUS', value: 'status' },
      { text: '', value: 'data-table-expand' },
    ],
    orders: [
    ],
    paging: {
      descending: true,
    },
  }),
  async mounted() {
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    const orders = await payments.getUserOrders({ uid });
    this.orders = await Promise.all(orders.map(async (order) => {
      const { sku, workshopId } = order;
      const id = `${workshopId}`;
      const product = sku ? await products.getProduct(sku)
        : await workshops.getWorkshop({ id });
      let od;
      let itemsList;
      if (product) {
        if (product.items) {
          itemsList = await Promise.all(
            product.items.map((item) => {
              let detail;
              if (item.type === 'workshop') {
                detail = workshops.getWorkshop({ id: `${item.id}` });
              } else if (item.type === 'pathway') {
                detail = pathways.getPathway({ id: `${item.id}` });
              }
              return detail;
            }),
          );
        }
        od = {
          id: `OD${order.id}`,
          orderdate: moment(order.added.toDate()).format('YYYY-MM-DD'),
          productname: product.name || product.title,
          price: order.price - order.discount,
          status: order.status,
        };
        if (itemsList) od.itemsList = itemsList;
      }
      return od;
    }));
    this.$store.dispatch('setLoading', false);
  },
};
</script>
