import firebase from '../index';

// PRODUCTS model
// sku --- key -> ItemtypesItemIdRunningNo ex: wspwcv0010010010001
// name
// items --- ex:[{ type: 'workshop', id: 1 },
//                { type: 'pathway', id: 1 }, { type: 'company-visit', id: 1 }]
// prices --- { earlyBird: { gold: 1, platinum: 2, silver: 3, nonMember: 4 }, sale: 5 }
// saleStart
// saleEnd
// earlyStart
// earlyEnd
// participants --- array of user who purchase product [uids]

// moving to product system
const products = firebase.firestore().collection('products');

const getProducts = () => new Promise((resolve, reject) => {
  products.orderBy('saleEnd', 'desc').get()
    .then((snapshot) => {
      resolve(snapshot.docs.map(doc => doc.data()));
    })
    .catch((e) => {
      reject(e);
    });
});

const getProduct = ({ sku }) => new Promise((resolve, reject) => {
  products.doc(sku).get()
    .then((snapshot) => {
      resolve(snapshot.data());
    })
    .catch((e) => {
      reject(e);
    });
});

export default {
  getProducts,
  getProduct,
};
