import firebase from '../index';

const pathways = firebase.firestore().collection('pathways');

const getPathways = () => new Promise((resolve, reject) => {
  pathways.orderBy('saleEnd', 'desc').get()
    .then((snapshot) => {
      resolve(snapshot.docs.map(doc => doc.data()));
    })
    .catch((e) => {
      reject(e);
    });
});

const getPathway = ({ id }) => new Promise((resolve, reject) => {
  pathways.doc(id).get()
    .then((snapshot) => {
      resolve(snapshot.data());
    })
    .catch((e) => {
      reject(e);
    });
});

export default {
  getPathways,
  getPathway,
};
